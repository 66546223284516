import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_data_table, {
      headers: _ctx.headers,
      items: _ctx.items,
      loading: _ctx.isLoadingTable,
      class: "elevation-1"
    }, {
      top: _withCtx(() => [
        _createVNode(_component_v_toolbar, { flat: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar_title, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Configurações de Agendamento")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      [`item.agendamentos_config_valores_id`]: _withCtx(({ item }) => [
        _createElementVNode("a", {
          href: '/settings/update/' + item.props.title.agendamentos_config_valores_id,
          class: "text-decoration-none"
        }, "Editar", 8, _hoisted_1)
      ]),
      _: 2
    }, 1032, ["headers", "items", "loading"])
  ]))
}