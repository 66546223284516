<template>
  <v-data-table-server v-model:items-per-page="privateOrdersPerPage" :headers="headers" :items="orders" :loading="isLoadingTable" class="elevation-1 orders-list fix-thead" hide-default-footer item-value="name" to="asd" :items-per-page-text="'Itens por página'">
    <template v-slot:body>
      <tr v-for="item in orders" :key="item._id" class="hover-table" @mousedown="mouseDownOrder(item)" @mouseup="mouseUpOrder(item)">
        <td width="3%" class="crown">
          <div v-if="item.tipo_veiculo == 'Super Vip'"> &#128081;</div>
        </td>
        <td width="4%" class="clickable" @mousedown="goToSchedule(item, $event)" @mouseup="mouseUpOrder(item)">{{ item.pedido_id }}</td>
        <td width="5%" class="clickable" @mousedown="goToReservation($event)" @mouseup="mouseUpOrder(item)">{{ item.reserva_id }}</td>
        <td width="5%" class="clickable" @mousedown="goToContract($event)" @mouseup="mouseUpOrder(item)">{{ item.contrato_nro }}</td>
        <td width="4%">{{ item.final_placa }}</td>
        <td width="6%" class="clickable" @mousedown="goToPlate($event)" @mouseup="mouseUpOrder(item)">{{ item.placa }}</td>
        <td width="12%">{{ item.versao }}</td>
        <td width="7%">{{ item.cor }}</td>
        <td width="7%">{{ item.nome_cliente }}</td>
        <td width="7%">{{ item.cpf_cliente }}</td>
        <td width="9%">{{ item.celular }}</td>
        <td width="11%">{{ item.loja_retirada }}</td>
        <td width="11%">{{ item.loja_atual }}</td>
        <td width="10%">{{ formatDate(item.data_agendamento) }}</td>
        <td width="7%">{{ item.responsavel_agendamento }}</td>
        <td width="9%">
          <v-chip variant="outlined" size="x-small">
            {{ item.status_monitoramento }}
          </v-chip>
        </td>
      </tr>
    </template>
    <template v-slot:[`bottom`]>
      <v-row class="d-flex align-center p-2" justify="end">
        <v-col>
          <div class="flex-end">
            <div class="item-per-page"> Pedidos por página: </div>
          </div>
        </v-col>
        <v-col cols="1">
          <v-select v-model="privateOrdersPerPage" :items="itemsPerPageOptions" variant="outlined" density="compact" hide-details></v-select>
        </v-col>
      </v-row>
    </template>
  </v-data-table-server>
  <v-container>
    <v-dialog v-model="confirmDialogReagendamento" max-width="500px">
      <v-card>
        <v-card-title class="headline mt-2">Agendamento ultrapassado</v-card-title>
        <v-card-text>Este agendamento foi realizado para uma data passada, deseja fazer o reagendamento?</v-card-text>
        <v-divider class="mt-2 pb-4"></v-divider>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDialogReagendamento = false">Cancelar</v-btn>
          <v-btn class="bg-red rounded-lg" @click="reschedule()">Reagendar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container>
    <v-dialog v-model="dialogInformation" width="600">
      <v-card>
        <v-row class="mx-3 mt-1">
          <v-col>
            <h3 class="h3-card">Informações do atendimento</h3>
            <v-row class="mt-2">
              <v-col md="4" sm="12">
                <v-text-field label="Pedido ID" v-model="active_item.pedido_id" readonly variant="outlined" density="compact" hide-details></v-text-field>
              </v-col>
              <v-col md="8" sm="12">
                <v-text-field label="Nome Cliente" v-model="active_item.nome_cliente" readonly variant="outlined" density="compact" hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-1">
              <v-col md="7" sm="12">
                <v-select v-model="active_status" :items="alternativeStatus" label="Status" density="compact" variant="outlined"></v-select>
              </v-col>
              <v-col md="5" sm="12">
                <v-tooltip text="Esta ação retorna o status do monitoramento ao fluxo normal, seguindo os status atualizados do pedido ou da frota. Execute esta ação somente após concluir todas as tratativas e garantir que os problemas foram resolvidos." location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" color="movida-orange" class="text-white h-full float-right" append-icon="mdi-sync" title="" @click="dialogConfirm = true" :disabled="disabledReset" :loading="loadingInformation">Restaurar Fluxo</v-btn>
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-textarea label="Adicionar informações do atendimento" v-model="active_information" :error-messages="error_info" variant="outlined" :disabled="disabledInformation"></v-textarea>
            <v-btn class="bg-movida-orange mb-2" elevation="0" @click="closeInformationModal()">Fechar</v-btn>
            <v-btn class="bg-movida-orange float-right mb-2" elevation="0" @click="saveInformation()" :loading="loadingInformation">Salvar</v-btn>
          </v-col>
        </v-row>
        <v-divider v-if="active_item_informations.length > 0"></v-divider>
        <v-row class="mx-3 mt-2" v-if="active_item_informations.length > 0">
          <v-col>
            <h3 class="h3-card">Histórico de Informações</h3>
            <v-card variant="outlined" v-for="information in active_item_informations" :key="information._id" class="my-1" width="100%" density="compact">
              <v-card-text class="information">
                <header> {{ formatDate(information.data) }} - {{ information.usuario }} </header>
                <main>
                  {{ information.informacao }}
                </main>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions class="v-card-dialog-actions"> </v-card-actions>
      </v-card>
      <v-dialog v-model="dialogConfirm" width="350">
        <v-card>
          <v-card-title>Confirmação</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <p>Deseja realmente restaurar o fluxo?</p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col>
              <v-btn class="bg-red" elevation="0" @click="dialogConfirm = false">Cancelar</v-btn>
              <v-btn class="bg-green float-right" elevation="0" @click="resetStatus()">Confirmar</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import { Order } from "@/models/backoffice/Order";
import { Vue, prop } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import * as services from "@/services";
import moment from "moment";

class Props {
  orders?: Order[];
  headers = prop({ default: [] });
  isLoadingTable = prop({ default: false });
  ordersPerPage = prop({ default: 15 });
  withDefault = prop({ default: "default" });
}
export default class ListOrdersComponent extends Vue.with(Props) {
  public privateOrdersPerPage = 15;

  public alternativeStatus = ["Tratativa Operacional", "Notificação Emitida", "Cancelar Pedido", "Entregue"];

  public active_status = "";

  public active_item: Order = {
    _id: "",
    pedido_id: 0,
    reserva_id: 0,
    contrato_nro: 0,
    final_placa: 0,
    placa: "",
    versao: "",
    cor: "",
    email: "",
    status: "",
    status_frota: "",
    franquia: "",
    tipo_veiculo: "",
    modalidade: "",
    nome_cliente: "",
    cpf_cliente: "",
    celular: "",
    loja_retirada: "",
    loja_atual: "",
    loja_atual_id: 0,
    data_agendamento: "",
    vendedor: "",
    canal_venda: "",
    loja_latitude: "",
    loja_longitude: "",
    responsavel_agendamento: "",
    responsavel_financeiro: "",
    status_monitoramento: "",
  };
  public active_item_informations: any[] = [];
  public active_information = "";
  public mouseIsDown = false;
  public idTimeout: any;
  public error_info = "";
  public loadingInformation = false;
  public disabledInformation = false;
  public urlBaseVetor = "https://vetorzkm.movida.com.br/";
  public itemsPerPageOptions = [15, 30, 50, 100];
  public dialogInformation = false;
  public confirmDialogReagendamento = false;
  public dialogConfirm = false;
  public disabledReset = true;

  mounted(): void {
    const currentURL = window.location.href;
    if (currentURL.includes("localhost") || currentURL.includes(".dev")) {
      this.urlBaseVetor = "https://vetor-op.dev.movidacloud.com.br/master/public/";
    } else {
      this.urlBaseVetor = "https://vetorzkm.movida.com.br/";
    }
  }

  data(): object {
    return {
      privateOrdersPerPage: this.ordersPerPage,
    };
  }

  @Watch("privateOrdersPerPage")
  onPrivateOrdersPerPageChanged(val: number) {
    if (val == -1) {
      val = 100;
    }
    this.$emit("update:ordersPerPage", val);
  }

  reschedule() {
    const routeData = this.$router.resolve({ path: "/schedules/store/" + this.active_item.pedido_id });
    window.open(routeData.href, "_blank");
  }

  @Watch("active_status")
  onActiveStatusChanged(val: string) {
    if (this.alternativeStatus.includes(val)) {
      this.disabledReset = false;
    } else {
      this.disabledReset = true;
    }
  }

  goToSchedule(pedido: Order, $event: MouseEvent) {
    $event.stopPropagation();
    this.mouseIsDown = true;
    this.idTimeout = setTimeout(() => {
      if (!this.mouseIsDown) {
        if (pedido.data_agendamento) {
          if (moment(pedido.data_agendamento).isBefore(moment())) {
            this.active_item = pedido;
            this.confirmDialogReagendamento = true;

            return;
          }
          return;
        }
        const routeData = this.$router.resolve({ path: "/schedules/store/" + pedido.pedido_id });
        window.open(routeData.href, "_blank");
      }
    }, 250);
  }

  goToReservation($event: MouseEvent) {
    $event.stopPropagation();
    this.mouseIsDown = true;
    this.idTimeout = setTimeout(() => {
      if (!this.mouseIsDown) {
        const routeData = this.urlBaseVetor + "central_reservas.php?scid=114&__do_log=1";
        window.open(routeData, "_blank");
      }
    }, 250);
  }

  goToContract($event: MouseEvent) {
    $event.stopPropagation();
    this.mouseIsDown = true;
    this.idTimeout = setTimeout(() => {
      if (!this.mouseIsDown) {
        const routeData = this.urlBaseVetor + "cad_contrato.php?scid=17&__do_log=1";
        window.open(routeData, "_blank");
      }
    }, 250);
  }

  goToPlate($event: MouseEvent) {
    $event.stopPropagation();
    this.mouseIsDown = true;
    this.idTimeout = setTimeout(() => {
      if (!this.mouseIsDown) {
        const routeData = this.urlBaseVetor + "cad_frotas.php?scid=33&__do_log=1";
        window.open(routeData, "_blank");
      }
    }, 250);
  }

  confirm() {
    this.dialogInformation = false;
  }

  mouseDownOrder(item: Order) {
    this.mouseIsDown = true;
    this.idTimeout = setTimeout(() => {
      if (!this.mouseIsDown) {
        this.openInformationModal(item);
      }
    }, 250);
  }

  mouseUpOrder(item: Order) {
    this.mouseIsDown = false;
  }

  resetStatus() {
    this.dialogConfirm = false;
    services.BackofficeService.resetStatusOrder(this.active_item.pedido_id).then((response) => {
      this.active_status = "";
      this.openInformationModal(this.active_item);
    });
  }

  isloadingInformation(loading: boolean) {
    this.loadingInformation = loading;
    this.disabledInformation = loading;
  }

  saveInformation() {
    if (this.active_status && this.active_status != this.active_item.status_monitoramento) {
      this.isloadingInformation(true);
      services.BackofficeService.saveStatusOrder(this.active_item.pedido_id, this.active_status).then((response) => {
        if (!this.active_information) {
          this.openInformationModal(this.active_item);
          this.isloadingInformation(false);
        }
      });
    }

    if (this.active_information) {
      if (this.active_information.length > 350) {
        this.error_info = "Máximo de 350 caracteres";
        return;
      }
      this.error_info = "";
      this.isloadingInformation(true);
      services.BackofficeService.saveInformationOrder(this.active_item.pedido_id, this.active_information).then((response) => {
        this.active_information = "";
        this.openInformationModal(this.active_item);
        this.isloadingInformation(false);
      });
    }
  }

  openInformationModal(item: Order) {
    try {
      services.BackofficeService.getInformationOrder(item.pedido_id).then((response) => {
        const pedido = response.pedido;
        const informacoes = response.informacoes;

        this.error_info = "";

        this.active_item = pedido;
        this.active_item_informations = informacoes;
        this.active_status = pedido.status_monitoramento;

        this.dialogInformation = true;
      });
    } catch (error) {
      console.error(error);
    }
  }

  closeInformationModal() {
    this.dialogInformation = false;
    this.$emit("closeInformationModal");
  }

  formatDate(date: string) {
    if (date) {
      return new Date(date).toLocaleString();
    }
    return "";
  }
}
</script>
<style>
.orders-list {
  font-size: 12px;
}

.crown {
  font-size: 16px;
}

.clickable {
  cursor: pointer;
  text-decoration: underline;
}

::v-deep .v-data-table .v-data-table__wrapper::-webkit-scrollbar {
  width: 10px;
}

.hover-table:hover {
  background: #eeeeee;
  cursor: pointer;
}

.hover-table:hover td {
  background: #eeeeee !important;
}

.item-per-page {
  font-size: 14px;
}

.fix-thead tbody {
  display: block;
  max-height: 70vh;
  overflow: auto;
}
.fix-thead thead,
.fix-thead tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}

.p-2 {
  padding: 0.2rem;
}

.h3-card {
  font-size: 18px;
  font-weight: 400;
  color: black;
  margin-bottom: 15px;
}

.information {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.information header {
  font-size: 0.9rem;
  font-weight: 400;
}

.information main {
  font-size: 0.9rem;
  font-weight: 300;
  color: rgb(65, 65, 65);
}

.v-card-dialog-actions {
  height: 10px;
}
</style>
