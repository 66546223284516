<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Atualizar Configuração</h1>
    <a class="text-decoration-none">
      <v-btn class="text-white" color="grey" href="/settings">
        <v-icon> mdi-plus </v-icon>
        Voltar
      </v-btn>
      <v-btn class="text-white ml-2" color="movida-orange" @click="updateItem" v-bind:disabled="btn_disabled">
        <v-icon> mdi-plus </v-icon>
        Salvar
      </v-btn>
    </a>
  </div>
  <div>
    <div>
      <v-col cols="12" md="6">
        <v-text-field v-model="item.value" label="Valor" hide-details="auto"></v-text-field>
      </v-col>
    </div>
  </div>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";

@Options({
  components: {},
})
export default class SettingsUpdateView extends Vue {
  public item: any = {};
  public isLoading = true;
  public id: number | null = null;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = false;
  $route: any;

  mounted(): void {
    this.id = this.$route.params.id;
  }

  closeSnack(): void {
    this.snackbar = false;
    this.$router.push("/settings");
  }

  async updateItem(): Promise<void> {
    if (this.id) {
      try {
        await services.ScheduleService.updateScheduleConfig(this.id, this.item.value);
        this.text = "Configuração atualizada com sucesso!";
        this.snackbar = true;
        this.btn_disabled = true;
        this.$router.push("/settings");
      } catch (e) {
        this.snackbar = true;
        this.text = "Erro ao atualizar configuração.";
      }
    }
  }
}
</script>

<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.ml-2 {
  margin-left: 8px;
}
</style>
