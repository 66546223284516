import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { watch } from "vue";
import * as Views from "../views";
import * as pages from "../pages";
import SettingsUpdateView from "@/views/settings/update.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "auth",
    component: pages.AuthPage,
    children: [
      {
        path: "",
        name: "login",
        component: Views.LoginView,
      },
      {
        path: "recovery",
        component: Views.RecoveryView,
      },
    ],
  },
  {
    path: "/home",
    name: "Início",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.HomeView,
      },
      {
        path: "profile",
        component: Views.ProfileView,
      },
      {
        path: "exit",
        component: Views.LogoutView,
      },
    ],
  },
  {
    path: "/select-module",
    name: "select-module",
    component: pages.AuthPage,
    children: [
      {
        path: "",
        component: Views.SelectModule,
      },
    ],
  },
  {
    path: "/MFA",
    name: "MFA",
    component: pages.AuthPage,
    children: [
      {
        path: "",
        component: Views.Mfa,
      },
    ],
  },
  {
    path: "/functionalities",
    name: "Funcionalidades",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.FuncionalidadesView,
      },
      {
        path: "store",
        name: "Criar Funcionalidade",
        component: Views.FuncionalidadeCreateView,
      },
      {
        path: "update/:id",
        name: "Atualizar Funcionalidade",
        component: Views.FuncionalidadeUpdateView,
      },
    ],
  },
  {
    path: "/modules",
    name: "Módulos",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.ModuleIndexView,
      },
      {
        path: "store",
        name: "Criar módulo",
        component: Views.ModuleCreateView,
      },
      {
        path: "update/:id",
        name: "Editar Módulo",
        component: Views.ModuleUpdateView,
      },
    ],
  },
  {
    path: "/users",
    name: "users-index",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.UserIndexView,
      },
      {
        path: "store",
        component: Views.UserCreateView,
      },
      {
        path: "update/:id",
        component: Views.UserUpdateView,
      },
    ],
  },
  {
    path: "/profiles",
    name: "profiles-index",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.ProfileIndexView,
      },
      {
        path: "store",
        component: Views.ProfileCreateView,
      },
      {
        path: "update/:id",
        component: Views.ProfileUpdateView,
      },
    ],
  },
  {
    path: "/routes",
    name: "routes-index",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.RoutesIndexView,
      },
      {
        path: "store",
        component: Views.RoutesCreateView,
      },
      {
        path: "update/:id",
        component: Views.RoutesUpdateView,
      },
    ],
  },
  {
    path: "/access-key",
    name: "access-key-index",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.AccessKeyIndexView,
      },
      {
        path: "store",
        component: Views.AccessKeyCreateView,
      },
      {
        path: "update/:id",
        component: Views.AccessKeyUpdateView,
      },
    ],
  },
  {
    path: "/monitoring",
    name: "monitoring-index",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.MonitoringIndexView,
      },
    ],
  },
  {
    path: "/catalog",
    name: "catalog-index",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.CatalogIndexView,
      },
      {
        path: "view/:id",
        component: Views.CatalogView,
      },
    ],
  },
  {
    path: "/schedules",
    name: "schedules-index",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.ScheduleIndexView,
      },
      {
        path: "store",
        component: Views.ScheduleCreateView,
      },
      {
        path: "store/:pedido_id",
        component: Views.ScheduleCreateView,
      },
      {
        path: "update/:id",
        component: Views.ScheduleUpdateView,
      },
      {
        path: "store/import",
        component: Views.ScheduleImportView,
      },
    ],
  },
  {
    path: "/showcase",
    name: "Vitrine",
    component: pages.HomePage,
    children: [
      {
        path: "update/:id",
        name: "Atualizar Vitrine",
        component: Views.ShowCaseUpdateView,
      },
    ],
  },
  {
    path: "/clusters",
    name: "clusters",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.ClusterIndexView,
      },
      {
        path: "store",
        name: "Criar cluster",
        component: Views.ClusterCreateView,
      },
      {
        path: "update/:id",
        name: "Editar cluster",
        component: Views.ClusterUpdateView,
      },
    ],
  },
  {
    path: "/deadlines",
    name: "deadlines-index",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.DeadlinesIndexView,
      },
      {
        path: "store",
        component: Views.DeadlinesEditView,
      },
      {
        path: "update/:id",
        component: Views.DeadlinesEditView,
      },
    ],
  },
  {
    path: "/leadtime",
    name: "leadtime",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.LeadTimeIndexView,
      },
    ],
  },

  {
    path: "/:catchAll(.*)",
    component: Views.V404View,
  },

  {
    path: "/settings",
    name: "settings-index",
    component: pages.HomePage,
    children: [
      {
        path: "",
        component: Views.SettingsIndexView,
      },
      {
        path: "update/:id",
        component: Views.SettingsUpdateView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

watch(
  () => router.currentRoute,
  async () => {
    console.log("route fullPath updated", router.currentRoute);
  }
);

router.beforeEach(async (to, from, next) => {
  const listRoutesAllowed = ["login"];
  if (!listRoutesAllowed.includes(<string>to.name)) {
    const tokenJson = localStorage.getItem("BEARER_TOKEN");
    let validation = true;
    if (tokenJson) {
      const expires_at = localStorage.getItem("EXPIRES_IN");
      if (expires_at) {
        validation = Number(expires_at) > new Date().getTime();
        console.log("verifica se token expirou", validation);
      }
    } else {
      next({ name: "login" });
    }
  }
  next();
});

const logoutNotRequest = () => {
  localStorage.removeItem("BEARER_TOKEN");
};
export default router;
