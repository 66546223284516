import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between mb-6" }
const _hoisted_2 = { class: "mt-15" }
const _hoisted_3 = { class: "mt-10" }
const _hoisted_4 = { class: "mt-4" }
const _hoisted_5 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_CountStatusComponent = _resolveComponent("CountStatusComponent")!
  const _component_FiltersOrdersComponent = _resolveComponent("FiltersOrdersComponent")!
  const _component_ListOrdersComponent = _resolveComponent("ListOrdersComponent")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "title" }, "Monitoramento", -1)),
      _createVNode(_component_v_btn, {
        color: "movida-orange",
        class: "text-white",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.exportOrders())),
        "append-icon": "mdi-file-export",
        loading: _ctx.isLoadingExport
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Exportar ")
        ])),
        _: 1
      }, 8, ["loading"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CountStatusComponent, {
        status: _ctx.status,
        error: _ctx.error,
        allStatus: _ctx.allStatus,
        onConfirmStatus: _ctx.confirmStatus,
        statusToShow: _ctx.statusToShow,
        statusToShowModality: _ctx.statusToShowModality,
        loading: _ctx.isLoadingStatus
      }, null, 8, ["status", "error", "allStatus", "onConfirmStatus", "statusToShow", "statusToShowModality", "loading"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FiltersOrdersComponent, {
        filters: _ctx.filters,
        error: _ctx.error,
        allStatus: _ctx.allStatus,
        onSearch: _ctx.search,
        ref: "filtersComponent"
      }, null, 8, ["filters", "error", "allStatus", "onSearch"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ListOrdersComponent, {
        orders: _ctx.orders,
        headers: _ctx.headers,
        isLoadingTable: _ctx.isLoadingTable,
        onCloseInformationModal: _ctx.closeInformationModal,
        ordersPerPage: _ctx.ordersPerPage,
        "onUpdate:ordersPerPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ordersPerPage) = $event))
      }, null, 8, ["orders", "headers", "isLoadingTable", "onCloseInformationModal", "ordersPerPage"])
    ]),
    (_ctx.totalOfRows > 0)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 0,
          class: "mt-2 d-flex align-center",
          justify: "center"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_v_pagination, {
                modelValue: _ctx.currentPage,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
                  _ctx.loadPage
                ],
                length: _ctx.numberOfPages,
                "total-visible": 7,
                color: "movida-orange",
                onNext: _ctx.nextPage,
                onPrev: _ctx.prevPage
              }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
              _createElementVNode("div", null, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados", 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" Fechar")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" mdi-information-outline")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}