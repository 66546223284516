<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import router from "@/router"; // Importe o roteador

@Options({
  components: {},
})
export default class RoutesIndexView extends Vue {
  public items: Array<any> = [];
  public isLoadingTable = true;
  public headers = [
    { title: "Chave", align: "end", key: "chave", sortable: false },
    { title: "Valor", align: "end", key: "value", sortable: false },
    { title: "Descrição", align: "end", key: "nome", sortable: false },
    { title: "Canal ID", align: "end", key: "canal_id", sortable: false },
    { title: "Data de Criação", align: "end", key: "criacao_data", sortable: false },
    { title: "Data de Atualização", align: "end", key: "atualizado_data", sortable: false },
    { title: "Ações", align: "end", key: "agendamentos_config_valores_id", sortable: false },
  ];

  mounted(): void {
    this.loadScheduleConfigs();
  }

  async loadScheduleConfigs(): Promise<void> {
    this.changeLoading();
    try {
      const response = await services.ScheduleService.getAllScheduleConfigs();
      this.items = response.results;
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }
}
</script>

<template>
  <div>
    <v-data-table :headers="headers" :items="items" :loading="isLoadingTable" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Configurações de Agendamento</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.agendamentos_config_valores_id`]="{ item }">
        <a :href="'/settings/update/' + item.props.title.agendamentos_config_valores_id" class="text-decoration-none">Editar</a>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
</style>
