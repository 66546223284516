<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Editar agendamentos</h1>
    <a class="text-decoration-none">
      <v-btn class="text-white mr-2" color="grey" @click="backToPreviousPage">Voltar</v-btn>
      <v-btn class="text-white" color="movida-orange" @click="update" v-bind:disabled="btn_disabled">Salvar</v-btn>
    </a>
  </div>
  <div>
    <v-col sm="12" md="6">
      <v-text-field v-model="orderId" density="comfortable" label="Pedido" :rules="rulesRequired" :loading="loading || loadingOrder" variant="solo" append-inner-icon="mdi-magnify" :disabled="true"></v-text-field>
    </v-col>
    <v-col sm="12" md="6">
      <v-autocomplete v-model="branchId" label="Loja de Retirada" :items="branchs" :rules="rulesRequired" :loading="loading || loadingBranch" item-title="nome" item-value="id" variant="solo" :disabled="true"></v-autocomplete>
    </v-col>
    <v-col sm="12" md="6">
      <v-autocomplete v-model="serviceId" label="Tipo de Agendamento" :rules="rulesRequired" :loading="loading || loadingService" :items="services" item-title="nome" item-value="servico_id" variant="solo" @update:modelValue="getAvailability"></v-autocomplete>
    </v-col>
    <v-col sm="12" md="6">
      <v-divider></v-divider>
    </v-col>
    <v-col sm="12" md="6">
      <VueDatePicker v-model="date" input-class-name="datepickerForm" locale="pt-br" select-text="selecionar" cancel-text="cancelar" :format="format" :enable-time-picker="false" :allowed-dates="allowedDates" @cleared="clearCalendar" @update:model-value="getHourAvailability"></VueDatePicker>
      <v-progress-linear indeterminate v-if="loading || loadingDate"></v-progress-linear>
    </v-col>
    <v-col sm="12" md="6">
      <v-select v-model="hour" :items="allowedHour" :rules="rulesRequired" :loading="loading || loadingHour" variant="solo" append-inner-icon="mdi-clock"></v-select>
    </v-col>
  </div>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import * as services from "@/services";
import { ScheduleStoreRequest } from "@/models/schedule/ScheduleStoreRequest";
import messages from "@/constants/messages";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { User } from "@/models";
import { getFromStorage } from "@/utils/localStorage";

@Options({
  components: {
    VueDatePicker,
  },
})
export default class ScheduleUpdateView extends Vue {
  public rulesRequired = [(value: any) => !!value || "Campo Obrigatório."];
  public messages = {};
  public loading = false;
  public loadingOrder = false;
  public loadingDate = false;
  public loadingHour = false;
  public loadingBranch = false;
  public loadingService = false;
  public needleSearch = null;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = false;
  public id: any | number = null;
  public peopleId: any | number = null;
  public branchs = [];
  public services = [];
  public allowedDates = [];
  public branchId = null;
  public serviceId = null;
  public orderId = null;
  public date: any = null;
  public hour: any = null;
  public availability: any = {};
  public allowedHour = [];
  public orders: any = [];
  public orderInfo: any = null;
  public user: User = {} as User;

  async mounted() {
    const currentRoute = this.$router.currentRoute.value;
    this.id = Number(currentRoute.params.id);
    this.peopleId = Number(currentRoute.query.peopleId);
    this.getDataUser();
    await this.getBranchs();
    this.getServices();
    this.getSchedule(this.id, this.peopleId);
    this.messages = messages;
  }

  getDataUser(): void {
    const dataUserFromStorage = getFromStorage("DATA_USER");
    if (dataUserFromStorage !== null) {
      this.user = JSON.parse(dataUserFromStorage);
    }
  }

  async getSchedule(scheduleId: number, peopleId: number) {
    try {
      this.changeLoading();
      const response = await services.ScheduleService.getSchedule(scheduleId, peopleId);
      if (response.results.length < 0) {
        return;
      }
      this.orderId = response.results?.metadata?.PedidoFrotaID;
      this.peopleId = response.results.pessoa_id;
      this.branchId = response.results?.metadata?.FilialID;
      this.serviceId = response.results?.servico?.id;
      const dateSchedule = response.results?.horario;

      this.date = dateSchedule;
      this.hour = moment(String(dateSchedule), "YYYY-MM-DD hh:mm:ii").format("hh:mm");
      this.getAvailability();
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }

  async getBranchs() {
    this.loadingBranch = true;
    const response = await services.ScheduleService.getAllBranch();
    this.branchs = response.results;
    this.loadingBranch = false;
  }

  async getServices() {
    this.loadingService = true;
    const response = await services.ScheduleService.getAllService();
    this.services = response.results;
    this.loadingService = false;
  }

  async getAvailability() {
    this.loadingDate = true;
    if (!this.branchId || !this.serviceId) return;
    const response = await services.ScheduleService.availability(this.branchId, this.serviceId);
    this.availability = response.results;
    this.loadAllowedDates();
    this.loadingDate = false;
  }

  format(date: any) {
    return moment(String(date)).format("DD/MM/YYYY");
  }

  selectBranch() {
    this.branchId = this.orderInfo.branchId;
  }

  getHourAvailability(date: any) {
    this.loadingHour = true;
    const dateFormatted = moment(String(date)).format("YYYY-MM-DD");

    this.hour = null;

    const availability = this.availability?.datas?.find(function (data: any) {
      return data.data == dateFormatted;
    });

    this.allowedHour = availability.intervalos.map(function (hour: any) {
      return hour.inicio;
    });
    this.loadingHour = false;
  }

  private loadAllowedDates() {
    this.allowedDates = this.availability?.datas?.map(function (data: any) {
      if (data.intervalos.length > 0) return data.data + " 00:00:00";
    });
  }

  changeLoading(): void {
    this.loading = !this.loading;
  }

  closeSnack(): void {
    this.snackbar = false;
    // this.$router.push("/schedules");
  }

  backToPreviousPage(): void {
    this.$router.go(-1);
  }

  async update(event: any): Promise<void> {
    this.changeLoading();
    const meta = {
      FilialID: this.branchId,
      PedidoFrotaID: this.orderId,
    };

    const date = moment(String(this.date)).format("YYYY-MM-DD");

    const storeRequest: ScheduleStoreRequest = {
      data: date + " " + this.hour,
      horario_config_id: this.availability?.servico?.horarios_config_id,
      pessoa_id: this.peopleId,
      metas: JSON.stringify(meta),
      user_id: this.user.id,
      cancelado: false,
    };

    const response = await services.ScheduleService.update(storeRequest, this.id);

    if (response.has_success) {
      this.text = messages.success;
      this.snackbar = true;
      this.btn_disabled = true;
      this.backToPreviousPage();
    } else {
      this.snackbar = true;
      this.text = messages.fail;
    }

    this.changeLoading();
  }

  clearCalendar() {
    this.hour = null;
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.dp__input {
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}

.datepickerForm {
  height: 59px;
}

.dp__action_select,
.dp__range_end,
.dp__range_start,
.dp__active_date {
  background-color: #ff9800 !important;
}
</style>
@/models/schedule/ScheduleStoreRequest
